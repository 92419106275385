jQuery(document).ready(function() {

    "use strict";

    // Init Theme Core
    Core.init();

    // Init DataTables

    $('.datatable').dataTable({
        "aoColumnDefs": [{
            'bSortable': false,
            'aTargets': [-1]
        }],
        "oLanguage": {
            "oPaginate": {
                "sPrevious": "",
                "sNext": ""
            }
        },
        "iDisplayLength": 25,
        "aLengthMenu": [
            [25, 50, 100, -1],
            [25, 50, 100, "All"]
        ],
        "sDom": '<"dt-panelmenu clearfix"lfr>t<"dt-panelfooter clearfix"ip>'/*,
        "oTableTools": {
            "sSwfPath": "vendor/plugins/datatables/extensions/TableTools/swf/copy_csv_xls_pdf.swf"
        }*/
    });

    var dataTableAccount = $('.datatable-account').DataTable({
        "aoColumnDefs": [{
            'bSortable': false,
            'aTargets': [-1]
        }],
        "oLanguage": {
            "oPaginate": {
                "sPrevious": "",
                "sNext": ""
            }
        },
        "iDisplayLength": 25,
        "aLengthMenu": [
            [25, 50, 100, -1],
            [25, 50, 100, "All"]
        ],
        "sDom": '<"dt-panelmenu clearfix">t<"dt-panelfooter clearfix"ip>'
    });
    if(dataTableAccount) {
        $('.filter-account-table', dataTableAccount.tables()[0]).on('keyup change', function () {
            var colId = $(this).attr('data-colid');
            console.log(colId);
            dataTableAccount
                .column(colId)
                .search(this.value)
                .draw();
        });
    }


    // Add Placeholder text to datatables filter bar
    $('.dataTables_filter input').attr("placeholder", "Enter Terms...");

});
